import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
// Data
import data from "../../layouts/tables/data/tenantsTableData";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {MuiChipsInput} from 'mui-chips-input'
import {useState, useEffect} from 'react';
import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import _ from 'lodash';

function Tenants() {
    const [open, setOpen] = React.useState(false);
    const [rows, setRows] = useState([]);
    const [tenantGUID, setGUID] = useState("");
    const [description, setDescription] = useState("");
    const [chips, setChips] = React.useState([])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
        setChips([])
        setGUID("");
        setDescription("");
    };

    const handleSave = () => {
        console.log("call update API " , tenantGUID + description + chips)
        setOpen(false);
    };

    const onChangeGUID = (event) => {
        setGUID(event.target.value)
    }

    const onChangeDescription = (event) => {
        setDescription(event.target.value)
    }

    const handleChange = (newChips) => {
        setChips(newChips)
    }

    const handleEdit = (id, tenantGUID, description, hostChips) => {
        setGUID(tenantGUID);
        setOpen(true);
        setDescription(description);
        setChips(hostChips);
    };

    const getTenantList = () => {
        let fakeDataList = [
            {
                id: 1,
                tenantGUID: "111",
                description: "test1",
                hosts: ["1", "2", "3"]
            },
            {
                id: 2,
                tenantGUID: "222",
                description: "test2",
                hosts: ["4", "5", "36"]
            }
        ]
        useEffect(() => {
            fetch('https://jsonplaceholder.typicode.com/posts?_limit=10')
                .then((response) => response.json())
                .then((dataList) => {
                    console.log(dataList)
                    let rows = [];
                    _.forEach(fakeDataList, data => {
                        let hostChips = data.hosts;
                        let row = {
                            tenantGUID: (
                                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                                    {data.tenantGUID}
                                </MDTypography>
                            ),
                            description: (
                                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                                    {data.description}
                                </MDTypography>
                            ),
                            hosts: (
                                <MuiChipsInput
                                    margin="dense"
                                    id="hosts"
                                    label="Hosts"
                                    type="text"
                                    fullWidth
                                    variant="standard" value={hostChips} disableEdition={true} hideClearAll={true}
                                    placeholder={""}
                                    unselectable={"on"}/>
                            ),
                            action: (
                                <>
                                    <IconButton style={{float: "right"}}
                                                aria-label="call the API">
                                        <SendIcon/>
                                    </IconButton>
                                    <IconButton style={{float: "right"}}
                                                aria-label="view the tenant detail">
                                        <VisibilityIcon/>
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleEdit(data.id, data.tenantGUID, data.description, hostChips)}
                                        style={{float: "right"}}
                                        aria-label="add to the new tenant for test">
                                        <EditIcon/>
                                    </IconButton>
                                </>
                            ),
                        }
                        rows.push(row);
                    })
                    setRows(rows);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }, []);
    }

    getTenantList();

    const data = {
        columns: [{Header: "tenantGUID", accessor: "tenantGUID", width: "30%", align: "left"},
            {Header: "description", accessor: "description", align: "left"},
            {Header: "hosts", accessor: "hosts", align: "center"},
            {Header: "action", accessor: "action", align: "center"}],
        rows: rows
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Tenant List
                                    <IconButton onClick={handleClickOpen} style={{float: "right"}} color="white"
                                                aria-label="add to the new tenant for test">
                                        <AddIcon/>
                                    </IconButton>
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={data}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
                <Dialog open={open} onClose={handleCancel}>
                    <DialogTitle>Add Test Tenant Case</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To add the case for this tenant, please enter your information here.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="tenantGUID"
                            label="Tenant GUID"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={tenantGUID}
                            onChange={onChangeGUID}
                        />
                        <TextField
                            margin="dense"
                            id="description"
                            label="Description"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={description}
                            onChange={onChangeDescription}
                        />
                        <MuiChipsInput
                            margin="dense"
                            id="hosts"
                            label="Hosts"
                            type="text"
                            fullWidth
                            variant="standard" value={chips} onChange={handleChange}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button onClick={handleSave}>Save</Button>
                    </DialogActions>
                </Dialog>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default Tenants;
