import Dashboard from "./layouts/dashboard";
import Tables from "./layouts/tables";
import Tenant from "./layouts/tenant";

// @mui icons
import HomeIcon from '@mui/icons-material/Home';
import ComputerIcon from '@mui/icons-material/Computer';
import AirplayIcon from '@mui/icons-material/Airplay';
const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <AirplayIcon fontSize="small"/>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Tenants",
    key: "tenants",
    icon: <HomeIcon  fontSize="small"/>,
    route: "/tenants",
    component: <Tenant />,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <ComputerIcon fontSize="small"/>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
];

export default routes;
